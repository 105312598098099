label .required,
.label .required {
  &::after {
    position: relative;
    top: 7px;
    margin-left: -4px;
    color: @plone-toolbar-private-color;
    content: "•";
    font-size: 200%;
    line-height: 0;
  }
}

.plone-modal-title {
  border-bottom: none;
}

.field,
.field.ArchetypesStringWidget {
  // display: flex;
  width: 100%;
  // align-items: center;
  padding-bottom: 10px;
  margin-bottom: 15px;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    display: inline-block;
    // width: 175px;
    // min-width: 175px;
    // padding-top: 0.7em;
    padding-right: 10px;
    margin-bottom: 5px;
    color: @blue;
    font-weight: bold;

    span {
      color: @text-color;
      font-weight: normal;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="file"],
  textarea {
    width: 100%;
    padding: 0.4em 1em;
    border: 1px solid @line-color-gray;
    background: transparent;
    border-radius: 0;
    color: @font-color;
    font: 1em/2 @copy-font;
    line-height: 30px;
  }

  input[type="text"] {
    max-height: 45px;
  }
}

.plone-modal-wrapper {
  .field input {
    width: calc(100% - 160px);
    max-width: 300px;
  }
}

input[type="submit"] {
  padding: 15px 20px;
  background-color: @blue;
  color: #fff;
  font-weight: bold;
}

.pfg-form {
  width: 100%;
  margin-top: 20px;

  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  .field {
    width: 75%;

    @media (max-width: @screen-sm-max) {
      width: 100%;
    }
  }

  .field.ArchetypesBooleanWidget {
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: @screen-sm-max) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    label {
      width: auto;
      padding: 0;
    }

    input {
      width: initial;
      margin-right: 10px;
      transform: scale(1.5);

      @media (max-width: @screen-sm-max) {
        margin-top: 10px;
      }
    }
  }

  // Select reset
  .field.ArchetypesSelectionWidget {
    select {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 0.4em 1em;
      border: 1px solid @line-color-gray;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      background: url(../img/icon-chevron-down.svg) no-repeat 97% center;
      border-radius: 0;
      color: @font-color;
      cursor: pointer;
      font: 1em/2 @copy-font;
      line-height: 30px;
      outline: none;
    }

    // The template is different, doesn't have a label tag...
    .label {
      display: inline-block;
      // width: 175px;
      // min-width: 175px;
      padding-top: 0.7em;
      padding-right: 10px;
      margin-bottom: 10px;
      color: @blue;
      font-weight: bold;

      .formHelp {
        color: @font-color;
        font-weight: normal;
      }
    }

    .pfg-radiobuttons {
      display: flex;
      width: 100%;
      flex-direction: column;

      label {
        width: 100%;
        padding: 0;
        color: @font-color;
        font-weight: normal;
      }

      div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    input[type="radio"] {
      width: initial;
      margin-right: 30px;
    }
  }

  .field.ArchetypesMultiSelectionWidget {
    select {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 0.4em 1em;
      border: 1px solid @line-color-gray;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border-radius: 0;
      color: @font-color;
      cursor: pointer;
      font: 1em/2 @copy-font;
      line-height: 30px;
      outline: none;
    }

    input {
      width: initial;
      margin-right: 10px;
      margin-left: 3px;
      transform: scale(1.5);
    }

    // The template is different, doesn't have a label tag...
    .label {
      display: inline-block;
      // width: 175px;
      // min-width: 175px;
      padding-top: 0.7em;
      padding-right: 10px;
      margin-bottom: 10px;
      color: @blue;
      font-weight: bold;

      .formHelp {
        color: @font-color;
        font-weight: normal;
      }
    }
  }

  .field.ArchetypesLinesWidget {
    div:not(.fieldErrorBox) {
      width: 100%;
    }
  }

  .field.ArchetypesFileWidget {
    div:not(.fieldErrorBox) {
      width: 100%;
    }
  }

  .field.ArchetypesLabelWidget {
    // The template is different, doesn't have a label tag...
    .label {
      display: inline-block;
      // width: 175px;
      // min-width: 175px;
      padding-top: 0.7em;
      padding-right: 10px;
      color: @blue;
      font-weight: bold;
    }
  }

  .field.ArchetypesLikertWidget {
    table {
      margin: 0;

      td {
        text-align: center;
        vertical-align: middle;
      }
    }

    .listing {
      padding: 0;
    }
  }

  .field.ArchetypesCaptchaWidget {
    .captchaImage {
      margin-bottom: 10px;

      @media (max-width: @screen-sm-max) {
        text-align: center;

        img {
          margin: 10px 0;
        }
      }
    }
  }

  .ArchetypesMultiSelectionValue label {
    color: @font-color;
    font-weight: normal;
  }

  .formControls {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .PFGFieldsetWidget {
    width: 100%;
  }

  .ArchetypesFileWidget {
    input {
      display: flex;
      align-items: center;
      padding: 0.6em 1em;
    }
  }
}

fieldset {
  // Chrome and Firefox set a `min-width: -webkit-min-content;` on fieldsets,
  // so we reset that to ensure it behaves more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359.
  min-width: 0;
  padding: 0;
  border: 0;
  margin: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: 1px solid @plone-legend-border-color;
  margin-bottom: @plone-line-height-computed;
  color: @plone-legend-color;
  font-size: (@plone-font-size-base * 1.5);
  font-weight: @plone-font-weight-light;
  line-height: inherit;
}

.field.error {
  .form-control-validation(
    @plone-state-error-text; @plone-state-error-text; @plone-state-error-bg
  );

  flex-flow: row wrap;
  background: lighten(@plone-state-error-bg, 20%);

  .fieldErrorBox {
    width: 100%;
    flex-grow: 1;
  }
  .box-shadow(0 0 0 5px lighten(@plone-state-error-bg, 20%));

  > label,
  div.error {
    color: @plone-toolbar-private-color;
  }

  div.error {
    padding-left: 30px;
    font-weight: @plone-font-weight-regular;

    &::before {
      position: relative;
      top: -1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-left: -30px;
      background-color: @plone-alert-error-bullet;
      border-radius: 20px;
      color: rgba(255, 255, 255, 0.9);
      content: "×";
      font-size: @plone-font-size-base;
      font-weight: @plone-font-weight-regular;
      line-height: 16px; //IE fix
      text-align: center;
      text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
    }
  }

  input,
  textarea,
  select {
    border-color: @plone-toolbar-private-color;

    &:focus {
      .box-shadow(0 0 8px lighten(@plone-toolbar-private-color, 40%));
    }
  }
}

.pattern-pickadate-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: @screen-sm-max) {
    justify-content: center;
  }
}

.pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
  width: 345px;
}

// Search in React app
#kitconcept-calendar {
  .element {
    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }
  }

  .input-group {
    position: relative;
    display: flex;
    // width: 50%;
    align-items: center;
    color: #00519d;

    &.datepicker {
      flex: 0 1 365px;

      input {
        padding: 6.4px 16px;
        border: none;
      }

      @media (max-width: @screen-md-max) {
        flex: 0 1 365px;
      }

      @media (max-width: @screen-sm-max) {
        flex: 0 1 0;
        margin-bottom: 10px;
      }

    }

    &.search {
      flex: 1;
      // Hide the location input
      // width: 70%;
      // width: 100%;
    }

    &.location {
      input {
        flex: 1;
        padding: 0.4em 1em 0.4em 45px;
      }
    }

    .icon-search {
      position: absolute;
      left: 5px;
      width: 25px;
      height: 25px;
    }

    .icon-location {
      position: absolute;
      left: 5px;
      width: 25px;
      height: 25px;
    }

    .searchableText {
      padding: 0.4em 1em 0.4em 45px;
    }
  }

  .target-audiences div a h3 {
    margin: 0;
  }

  .target-audiences-small {
    margin-top: 18px;
  }
}

// Login form exceptions
#login-form {
  .field {
    display: flex;
  }

  label {
    width: 175px;
    min-width: 175px;
  }
}
