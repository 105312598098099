/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Icon Fonts
*********************************/

// deleted –
// we use fontAwesome here

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  padding: 0;
  margin: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  display: block;
  width: 100%;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides::after {
  display: block;
  height: 0;
  clear: both;
  content: "\0020";
  line-height: 0;
  visibility: hidden;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */

/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
  position: relative;
  zoom: 1;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

// styles for the slides
// ======================================================

// the new carousel w/ flexslider
// -------------------------------------------------------

.flexslider {
  border: 0;
  margin: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: 0 1px 2px fade(@black, 30%);

  @media (max-width: @screen-xs-max) {
    margin-right: -15px;
    margin-left: -15px;
  }

  .slides li {
    padding: 0;
    margin: 0;
  }
}

.flex-carousel {
}

.flex-carousel-item {
  position: relative;
  background-color: #efefef;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  //min-height:28em;
}

.flex-carousel-caption {
  position: absolute;
  z-index: 0;
  right: 56px;
  bottom: 40px;
  width: 45%;
  padding: 0.8em 1.4em 1.5em;
  background: @blue;
  text-shadow: none;

  h3 {
    padding: 0 0 0.3em;
    border: none;
    margin: 0;
    color: @white;
    font: 500 2em/1.4 @copy-font;
  }

  p {
    margin: 0;
    color: @green;
    font: 500 1em/1.4 @copy-font;
  }

  @media (max-width: @screen-md-max) {
    min-height: 11em;
    font-size: 86%;
  }

  &.right-aligned {
    float: right;
    text-align: right;

    h1,
    p {
      color: @white;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .flex-direction-nav {
    display: none;
  }

  .flex-carousel-item {
    position: relative;
    min-height: 0;
  }

  .flex-carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    display: inline-block;
    width: auto;
    min-height: 8em;
    padding: 1.2em 30px 1.5em;
    margin: 0;
    background: @blue;
    float: none;

    h3 {
      padding: 0;
      border: none;
      margin: 0;
      color: @white;
      font: 500 1.4em/1.4 @copy-font;
    }
  }
}

.flex-control-nav {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

// kill styles from content stylesheet

ol.flex-control-nav {
  bottom: -3em;
  background: transparent;

  li {
    display: inline-block;
    *display: inline;
    width: 17px;
    padding: 0;
    margin: 0 10px;
    text-align: center;
    zoom: 1;

    &::before {
      display: none;
    }
  }
}

.flex-control-paging li a {
  display: inline-block;
  width: 11px;
  height: 11px;
  padding: 0;
  border: 3px solid transparent;
  margin: 0;
  background: @blue;
  border-radius: 50px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  text-indent: -9999px;
}

.flex-control-paging li a:hover {
  background: @blue;
  background: fade(@blue, 70%);
}

.flex-control-paging li a.flex-active {
  position: relative;
  top: -0.3em;
  width: 17px;
  height: 17px;
  border: 1px solid @blue;
  background: transparent;
  cursor: default;
  text-align: center;
}

// changing the direction nav handlers

/* Direction Nav */
.flex-direction-nav a {
  position: absolute;
  top: 50%;
  display: block;
  width: 3em;
  height: 3em;
  border: 0;
  margin: -40px 0 0;
  background: #fff;
  border-radius: 300px;
  box-shadow: 0 2px 2px fade(@black, 10%);
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  text-indent: -9999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.flex-direction-nav .flex-next,
.flex-direction-nav .flex-prev {
  &::after {
    position: absolute;
    top: 0;
    left: 0.1em;
    display: block;
    width: 3em;
    height: 3em;
    background: url(../img/icon-next.svg) no-repeat center center;
    color: @gray;
    content: "";
    text-align: center;
  }
}

.flex-direction-nav .flex-next {
  right: -5em;
}

.flex-direction-nav .flex-prev {
  left: -5em;

  &::after {
    left: -0.1em;
    background: url(../img/icon-prev.svg) no-repeat center center;
    text-indent: -4px;
  }
}

@media screen and (max-width: 1320px) {
  .flex-direction-nav .flex-prev {
    left: 10px;
    opacity: 1;
  }

  .flex-direction-nav .flex-next {
    right: 10px;
    opacity: 1;
  }
}

@media (max-width: @screen-xs-max) {
  .flex-carousel {
    .container {
      padding: 0;
    }
  }

  .flex-control-nav {
    bottom: -40px;
  }
}
