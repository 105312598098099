// vertical navigation
// ------------------------------------

nav.vertical-navi {
  display: none;

  @media (min-width: @screen-sm-min) {
    display: block;
    margin-bottom: 40px;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      display: block;
      padding: 0;
      margin: 0;

      a,
      .inactive > a {
        display: block;
        padding: 0.5em 0.5em 0.5em 1em;
        border: 0;
        border-top: 1px solid #e2e7eb;
        color: @blue;
        font: 500 1em/1.2 @copy-font;

        &:hover {
          background: @header-blue;
        }
      }
    }
  }

  ul {
    border-bottom: 1px solid #e2e7eb;
    margin: 0;

    > li {
      > a {
        font-weight: 500;
      }

      &.inactive > a,
      &.navTreeItemInPath:first-child > a {
        display: block;
        background: @green;
        color: @blue;
        font-weight: 700;
      }

      ul {
        border: none;
        margin: -1px 0 0 1.5em;

        > li {
          > a {
            //padding-left:2.5em;
            font-size: 0.9em;
            font-weight: 400;
          }

          &.inactive > a {
            background: @header-blue;
            color: @blue;
            //padding-left:2.5em;
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
