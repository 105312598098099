// termin teaser

.termin-item {
  margin-bottom: 1em;

  .date {
    display: block;
    width: 56px;
    //height: 56px;
    padding: 7px 0;
    background: @header-blue;
    color: @blue;
    float: left;
    font: 700 1.6em/1 @copy-font;
    text-align: center;
    text-transform: uppercase;

    span {
      display: block;
      font: 500 0.6em/1.4 @copy-font;
    }
  }

  .termin-item-body {
    min-height: 4.8em;
    padding-bottom: 1em;
    border-bottom: 1px solid @gray-lighter;
    margin-left: 70px;

    .icon-date {
      margin-right: 5px;
    }

    .dash-date {
      padding: 0 5px;
    }
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 1em;
    line-height: 1.2;
  }

  p {
    padding: 0;
    margin: 0;
    font: 0.85em/1.6 @copy-font;
  }
}

// the event-listings (big)

.termine-big {
  margin-top: 2em;

  .first-row {
    position: relative;
  }

  .termin-item {
    margin-bottom: 1em;

    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: left;
      padding: 0.25em 0;

      &.pbh {
        align-items: unset;
        justify-content: center;
        font: 700 2.8em/1 @copy-font;

        span {
          display: block;
          font: 500 0.3em/1.4 @copy-font;
        }
      }

      .date-block {
        display: block;
        width: 100%;

        .date-block-day {
          display: inline-block;
          width: 50%;
          font: 700 2em/1 @copy-font;
          text-align: right;
        }

        .date-block-month {
          display: inline-block;
          width: 50%;
          padding-left: 0.4em;
          font: 700 0.8em/1.2 @copy-font;
          text-align: left;
        }

        &.date-block-to {
          margin-top: -3px;
        }

        .date-block-to {
          margin-top: -10px;
          line-height: 0.5em;
          text-align: center;
        }
      }

      &.single-date {
        padding-top: 1.18em;
      }

      @media (max-width: @screen-sm-max) {
        font: 700 1.8em/1 @copy-font;

        span {
          font: 500 0.32em/1.4 "Roboto", sans-serif;
        }
      }

      @media (min-width: @screen-md-min) {
        width: 90px;
        height: 90px;
      }
    }

    .date.extended {
      display: flex;
      flex-direction: column;

      .dash {
        padding-right: 15px;
        font-size: 20px;
        line-height: 10px;
        text-align: right;
      }

      .startdate,
      .enddate {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 30px;
        font-weight: 700;

        .monthyear {
          display: block;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .termin-item-body {
      min-height: 90px;
      color: #666;

      @media (min-width: @screen-md-min) {
        margin-left: 110px;
      }
    }

    .first-row {
      font-size: 0.9em;
      font-weight: 600;
    }

    h3 {
      padding: 0.7em 0 0.2em;
      margin: 0;
      font-size: 1.3em;
      line-height: 1.2;
    }

    p {
      padding: 0;
      margin: 0;
      font: 0.85em/1.6 @copy-font;
    }
  }
}

// Portlets override
.portlet .termine-big .termin-item-body h3,
.events-block .termine-big .termin-item-body h3 {
  padding-top: 0;
  font-size: 1.2em;
}

.zielgruppe-badge {
  display: inline-block;
  height: 2em;
  padding: 0.2em;
  background: @green;

  svg {
    width: 1.7em;
    height: 1.7em;
    margin: 0 0.1em;
  }
}

.event-label {
  display: inline-block;
  height: 2em;
  padding: 0.2em 0.8em;
  background: @blue;
  color: @white;
  font-weight: 600;
}

// termin single

.first-row-termin-single {
  margin: 1.5em 0 3.5em;
  font-size: 0.9em;
}

.pbhcalendar {
  .termine-big .termin-item h3 {
    padding-top: 0.2em;
  }
}
