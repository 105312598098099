// gallery-items
// size is controlled via the grid

.gallery-item {
  margin-bottom: 1em;

  figure {
    margin-bottom: 1.5em;

    img {
      width: 100%;
    }

    figcaption {
      //font: .85em/1.6 @copy-font;
    }
  }
}

.lightbox {
  top: 50px !important;
}
