/* ==========================================================================
   Getting it all together
   ========================================================================== */

@import "cornerstone/normalize";
@import "cornerstone/basic-styles";
@import "cornerstone/helperclasses";
@import "cornerstone/leGrid";

// User styles

@import "theme/variables";
@import "theme/scaffolding";
@import "theme/typography";
@import "theme/header";
@import "theme/subheader";
@import "theme/hero";
@import "theme/flexslider";
@import "theme/breadcrumb";
@import "theme/main-navi-desktop";
@import "theme/main-navi-desktop-vertical";
@import "theme/off-canvas";
@import "theme/main-navigation-mobile";

@import "theme/content-sections/target-audiences";
@import "theme/content-sections/member-benefits";
@import "theme/content-sections/teaser";
@import "theme/content-sections/gallery-item";
@import "theme/content-sections/termin";
@import "theme/content-sections/meldung";
@import "theme/content-sections/listing";
@import "theme/content-sections/column-list";
@import "theme/content-sections/author-box";
@import "theme/content-sections/pager";
@import "theme/content-sections/map-box";
@import "theme/content-sections/forms";
@import "theme/content-sections/personal-details";
@import "theme/content-sections/templates";

@import "theme/footer";

// Barceloneta additions
@import "plone.toolbar.vars.less";
@import "../barceloneta/less/variables.plone.less";
@import "../barceloneta/less/mixin.prefixes.plone.less";
@import "../barceloneta/less/mixin.forms.plone.less";
@import "../barceloneta/less/alerts.plone.less";
@import "../barceloneta/less/search.plone.less";
@import "../barceloneta/less/formtabbing.plone.less";

// custom
@import "theme/forms";
@import "theme/custom";

// After
@import "../barceloneta/less/pickadate.plone.less";

// plugins
@import (css) "../css/lightbox.min.css";
@import "theme/react-dates-custom.less";
@import (css) "../css/jquery.mmenu.css";
// @import (css) "../css/jquery.mmenu.navbars.css";

@import "cornerstone/print";
@import "print";

@isPlone:false;
@isPlone: false;