// personal details (OÜ-detrails view
// author-box.less and dl of typography.less

.personal-details {
  aside.author {
    width:20%;
    margin:2.6em 0 1.4em 1em;
  }
  .dl-container {
    float:left;
    max-width:75%;
  }
}