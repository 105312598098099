html,
body {
  color: @text-color;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.6;

  @media (min-width: @screen-sm-min) {
    font-size: 14px;
  }

  @media (min-width: @screen-md-min) {
    font-size: 15px;
  }

  @media (min-width: @screen-lg-min) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4 {
  color: @blue;
  font-weight: 500;
}

h1 {
  margin: 1em 0 0;
  font: 500 2em/1.2 @copy-font;
}

h1.documentFirstHeading {
  margin: 0.5em 0;
}

.has-header-image h1.documentFirstHeading {
  margin: 1em 0 0.5em;
}

h2 {
  padding-top: 0.8em;
  border-bottom: 1px solid @green;
  font: 500 1.5em/1.6 @copy-font;
}

.icon.icon-external-link {
  margin-bottom: 6px;
}

.icon.nomargin {
  margin: 0
}

#portal-column-two h2 {
  padding-top: 0;
  margin-top: 0;
}

h3 {
  font: 500 1.4em/1.6 @copy-font;
}

h4 {
  font: 500 1.1em/1.6 @copy-font;
}

p {
}

.intro,
.documentDescription,
.homepage-richtext p,
.homepage-richtext div {
  margin-top: 1em;
  margin-bottom: 1em;
  color: @gray-light;
  font: 400 1.1em/1.6 @copy-font;

  &.small {
    font: 400 0.9em/1.6 @copy-font;
  }
}

.date {
  padding: inherit;
  // Overrides Plone
  border: none;
  color: @gray-light;
  font: 400 0.8em/1.2 @copy-font;
  text-align: inherit;
}

.content {
  section {
    .clearfix;
  }

  a {
    border-bottom: 2px solid @green;
    color: @blue;
    font-weight: bold;
    text-decoration: none;
  }

  a.btn {
    padding: 0.4em 2em;
    border: 1px solid @blue;
    color: @blue;
    font-weight: bold;
  }

  ul {
    padding: 0;
    margin: 1em 0 2em;
    list-style: none;

    li {
      position: relative;
      padding: 0.2em 0 0.2em 1.5em;
      margin: 0;

      &::after {
        position: absolute;
        top: 0.7em;
        left: 0;
        width: 10px;
        height: 10px;
        background: @green;
        border-radius: 50px;
        content: "";
      }
    }
  }

  h2 > a {
    border-bottom: none;
  }
}

// Tabelle

.responsive-table {
  overflow: scroll;
  overflow-y: hidden;

  @media (max-width: @screen-xs-max) {
    width: 100%;
  }
  tr:nth-child(even) td {
    background: fade(@header-blue, 50%);
  }
}

table {
  margin: 2em 0;

  th,
  td {
    padding: 0.2em 0.5em;
    border-right: 2px solid @white;
    border-bottom: 1px solid @header-blue;
    text-align: left;
    vertical-align: top;
  }

  th {
    border-bottom: 1px solid @green;
    background: fade(@green, 30%);
    color: @blue;
  }

  td {
  }

  .right {
    text-align: right;
    vertical-align: bottom;
  }

  .no-wrap {
    white-space: nowrap;
  }
}

// definition list

.dl-container {
  margin: 2em 0;
}

dl {
  padding: 0 0 0.2em;
  border-bottom: 1px solid @line-color-gray;
  margin: 0 0 0.2em;

  .clearfix;

  dt {
    display: inline-block;
    width: 15%;
    color: @blue;
    float: left;
    font-weight: 600;
  }

  dd {
    position: relative;
    width: 75%;
    float: left;
  }
}

.icon {
  width: 1.3em;
  height: 1.3em;
  margin: 0 0 0 0.4em;
  color: @blue;
}

.icon-pull {
  position: absolute;
  top: 0.2em;
  left: -2em;
}

// figure

figure {
  width: 100%;
  margin: 0.3em 0 1.5em;

  // Variables: different sizes available (to be defined)

  @media (min-width: @screen-md-min) {
    &.size-1 {
      width: 33%;
    }

    &.size-2 {
      width: 42%;
    }
  }

  // Variables: float to either side

  &.float-left {
    margin-right: 2em;
    float: left;
  }

  &.float-right {
    margin-left: 2em;
    float: right;
  }

  img {
  }

  figcaption {
    padding: 0.8em;
    background: fade(@header-blue, 100%);
    font: 0.85em/1.4 @copy-font;
  }
}
