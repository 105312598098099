// column lists

.content {

  .column-listing-wrapper {

  }
  .column-listing-navi {
    ul {
      margin:0;
      padding:1em 0;
      li {
        margin:0 .2em 0 0;
        padding:0;
        display:inline-block;
        &:after {
          display:none;
        }
        a {
          display: inline-block;
          margin-bottom: .4em;
        }
      }
    }
  }


  ul.column-list {
    column-count: 3;
    column-gap:30px;
    padding:1em 0 4em;
    margin:0;

    @media (max-width:@screen-md-max) {
      column-count: 2;
    }
    @media (max-width:@screen-xs-max) {
      column-count: 1;
    }

    li {
      padding:0;
      margin:0;
      -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
      page-break-inside: avoid; /* Firefox */
      break-inside: avoid; /* IE 10+ */

      a {
        font:400 1em/1.4 @copy-font;
        border-bottom:1px solid @line-color-gray;
        display:block;
        padding:.4em 0;
      }
    }
    li:after {
      display:none;
    }
  }

  // special: the collapsable li (this is stell highly experimental
  // ===============================================================

  .column-list-collapsable {
    max-height:auto;
    overflow:visible;
    position:relative;
    margin-bottom:3em;

    &:before {
      position: absolute;
      z-index:11;
      width:100%;
      height:0;
      content:"";
      display:block;
      top:2em;
      left:0;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30,ffffff+81,ffffff+100&0+0,0+30,1+81,1+100 */
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 81%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 30%,rgba(255,255,255,1) 81%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 30%,rgba(255,255,255,1) 81%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    &:after {
      content:"alle anzeigen";
      position: absolute;
      bottom:-1em;
      left:50%;
      text-align:center;
      //width:100%;
      display:inline-block;
      margin:auto auto auto -50px;
      z-index:12;
      text-decoration:none;
      border:1px solid @blue;
      padding:.4em 2em;
      font-weight:bold;
      color:@blue;
    }
  }

  .column-list-collapsable.column-list-collapsed {
    max-height:22em;
    overflow:hidden;
    //padding-bottom:2em;
    position:relative;

    &:before {
      height:100%;
    }

    &:after {

    }
  }


}