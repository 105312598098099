@offcanvas-width: 80%;
@offcanvas-max-width:480px;

html,
body {
  overflow-x: hidden;
}

html.scroll-prevention {
  overflow: hidden;
}

.product-navigation-offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: @offcanvas-width;
  background: @blue;
  font-size: 1.1em;
  overflow-x: auto;
}

.the-wrapper {
  position: relative;
  background: #fff;
  // z-index:100; // Victor: Removed because it was interferring with the toolbar
  box-shadow: -2px 0 4px fade(@black, 25%);
  overflow-x: hidden;
  transition: transform 0.4s ease-in-out;

  &.open {
    position: relative;
    z-index: 100;
    transform: translate(@offcanvas-width, 0);
  }
}

header.mobile-header {
  transition: transform 0.4s ease-in-out;

  &.open {
    z-index: 10000;
    transform: translate(@offcanvas-width, 0);
  }
}

@media (min-width: @screen-sm-max) {
  .product-navigation-offcanvas {
    width: 0;
  }

  .the-wrapper {
    &.open {
      transform: translate(0, 0);
    }
  }
}
