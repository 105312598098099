@media print {
  .main-navi,
  #edit-zone,
  .breadcrumb,
  .language,
  .login,
  .search,
  .support-navi,
  .footer-middle,
  .footer-lower nav,
  .dpg-footer-links,
  #sidebar,
  .burger {
    display: none !important;
  }

  a[href]::after {
    content: none !important;
  }

  .col-xs-12.col-sm-6.content {
    width: 100%;
  }

  .subheader {
    border-bottom: 1px solid #dfdfdf;

    .col-xs-2.col-sm-3 {
      display: none;
    }
  }

  .portaltype-homepage {
    .hero::after {
      border: none;
    }

    .flex-control-nav,
    .flex-direction-nav {
      display: none;
    }

    .flex-carousel-item a {
      text-decoration: none;
    }

    .flex-carousel-caption {
      position: static;
      width: 100%;
    }
  }
}
