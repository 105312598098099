.yellow-box {
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: 1px solid @white;
      background: @green;
      color: @blue;
      text-align: center;
      text-decoration: none;

      &:hover {
        background: @blue;
        color: @green;

        h3 {
          color: @green;
        }
      }

      h3,
      p {
        padding: 0;
        margin: 0 0 10px;
      }

      h3 {
        font: 700 1.1em/1.2 @copy-font;
      }

      p {
        font: 400 0.8em/1.4 @copy-font;
      }
    }
  }
}

iframe[src*="youtube.com"] {
  @media (max-width: @screen-xs-max) {
    width: 100%;
  }
}
