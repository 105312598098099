// Contact form

.form {
  .element {
    display: flex;
    margin-top: 1.4em;
    .clearfix;
  }

  input[type="text"],
  input[type="email"],
  textarea,
  .select-container {
    width: 100%;
    padding: 0.4em 1em;
    border: 1px solid @line-color-gray;
    background: transparent;
    border-radius: 0;
    color: @font-color;
    font: 1em/2 @copy-font;

    &.short-left,
    &.short-right,
    &.short-middle {
      width: 55%;
    }

    &.short-right {
      border-left: 0;
    }

    &.short-left {
      border-right: 0;
    }

    &.short-middle {
      border-right: 0;
      border-left: 0;
    }
  }

  input[type="checkbox"] {
    margin-right: 0.5em;
  }

  // selects get special treatment

  select {
    position: relative;
    z-index: 1;
    width: 100%;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    outline: none;
  }

  .select-container {
    position: relative;
    padding-right: 0;

    &::after {
      position: absolute;
      top: 0.4em;
      right: 0.1em;
      width: 2.4em;
      height: 2em;
      border-left: 1px solid @line-color-gray;
      background: url(../img/icon-chevron-down.svg) no-repeat center center;
      content: "";
      cursor: pointer;
    }
  }

  select::-ms-expand {
    /* for IE 11 */
    display: none;
  }

  label {
    width: 5em;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 0.7em;
    color: @blue;
    //display:none;
    font: 600 1em/1.4 @copy-font;
  }

  label.inline-label {
    display: inline-block;
    color: @gray;
  }

  .btn {
    padding: 0.4em 1.4em;
    border: none;
    background: @blue;
    border-radius: 0;
    color: @white;
    font-weight: 600;
  }

  p.descriptive {
    margin-bottom: 0.4em;
    color: lighten(@gray, 10%);
    font: 0.9em/1.4 @copy-font;
  }
}

.filter-form-container {
  padding-bottom: 1em;
  border-bottom: 1px solid @line-color-gray;
}

.form.filter-form {
  label {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 0.5em;
  }

  .element {
    margin-top: 1em;
  }

  .element.separator {
    padding-right: 1.5em;
    border-right: 1px solid @line-color-gray;

    @media (max-width: @screen-sm-max) {
      padding-right: 0;
      border: none;
    }
  }

  // Fake pull down for the filter

  .filter-box {
    position: relative;
    width: 100%;
    padding: 0.4em 1em;
    border: 1px solid @line-color-gray;
    background: transparent;
    border-radius: 0;
    color: @font-color;
    font: 1em/2 @copy-font;

    &::after {
      position: absolute;
      top: 0.5em;
      right: 0.8em;
      width: 1.5em;
      height: 1.5em;
      border-left: 1px solid @line-color-gray;
      background: url(../img/icon-chevron-down.svg) no-repeat right 0.6em;
      content: "";
    }

    .icon {
      position: relative;
      display: inline-block;
    }

    .icon-chevron-down {
      width: 10px;
      height: 6px;
    }

    ul {
      position: absolute;
      z-index: 9999;
      top: 2.9em;
      left: 0;
      display: block;
      width: 32em;
      padding: 0;
      padding: 1em;
      border-top: 1px solid fade(@black, 10%);
      margin: 0;
      background: #fff;
      background: fade(@white, 99%);
      box-shadow: 0 2px 2px fade(@black, 20%);
      list-style: none;
      opacity: 0;
      transition: opacity 0.2s linear, visibility 0.2s linear;
      transition-delay: 0.2s;
      visibility: hidden;

      @media (max-width: @screen-sm-max) {
        width: 20em;
      }

      @media (min-width: @screen-sm-min) {
        column-count: 2;
        column-gap: 10px;
      }

      li {
        .clearfix;

        padding: 0.1em 0 0 0.8em;

        &::after {
          display: none;
        }

        input[type="check"] {
        }

        label {
          color: darken(@text-color, 10%);
          font: 400 0.95em/1 @copy-font;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.form.filter-form label.vereinigungen-label {
  width: 30%;
  flex-grow: 0;

  @media (max-width: @screen-sm-max) {
    width: 100%;
  }
}

.vereinigungen-select {
  flex: 1;

  .select__control {
    background-color: transparent;
    border-radius: 0;
  }
}
