// Le Pager

.pagination,
.navigation.pager {
  box-sizing: border-box;
  padding: 0.5em 0 2em;
  color: darken(@header-blue, 20%);
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;

  @media (max-width: @screen-xs-max) {
    font-size: 80%;
  }

  .pages {
    display: none;
  }

  a,
  span.current {
    position: relative;
    display: inline-block;
    width: auto;
    //margin:3px;
    // height: 2.5em;
    padding: 0.5em 0.8em;
  }

  a {
    border-bottom: none;
    text-decoration: none;

    &:hover {
      background: @gray;
      color: #fff;
    }
  }

  span.current {
    width: auto;
    height: 2.5em;
    background: @header-blue;
    color: @blue;
  }

  span.ellipsis {
    padding: 0.5em 0.8em;
  }

  a.next,
  a.prev {
    position: relative;
    top: -0.1em;
    width: 3em;
    height: 3em;
    padding: 0.5em 0.8em;
    border-right: 1px solid darken(@header-blue, 10%);

    svg {
      width: 0.8em;
      height: 1.9em;
    }
  }

  a.next {
    border-right: 0;
    border-left: 1px solid darken(@header-blue, 10%);

    svg {
      transform: rotate(180deg);
    }
  }
}

.pagination {
  ul {
    display: flex;
  }

  ul li::after {
    content: initial;
  }

  a:hover {
    height: auto;
    cursor: pointer;
  }

  li > span {
    position: relative;
    display: inline-block;
    width: auto;
    // height: 2.5em;
    padding: 0.5em 0.8em;
  }
}

@media (max-width: @screen-sm-max) {
  .navigation {
    font-size: 0.9em;
  }
}

// Pager w/ month for Events

.pager-year {
  text-align: center;

  h3 {
    padding: 0;
    margin: 2em 0 0;
  }
}

.navigation.pager.pager-month {
  padding-bottom: 0;
  font-size: 1.05em;

  a,
  span,
  span.current {
    padding: 0.5em 0.4em 0.5em 1.3em;
  }

  a {
    border-bottom: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: @gray;
      color: #fff;
    }

    &.past-month {
      color: fade(@blue, 50%);
    }
  }

  a.next,
  a.prev {
    padding: 0.5em 0.8em;
  }

  span {
    border-bottom: none;

    &:hover {
      color: #fff;
    }

    &.past-month {
      color: fade(@blue, 50%);
    }
  }

  span.current {
    position: relative;
    width: auto;
    background: @header-blue;
    color: @blue;
    cursor: pointer;

    &::after {
      position: absolute;
      top: 0.95em;
      left: 0.35em;
      display: block;
      width: 1em;
      height: 1em;
      background: url(../img/icon-times.svg) no-repeat;
      content: "";
    }
  }
}

// Pager style alphabeth navi

.navigation.pager.alphabeth {
  margin: 1em 0 2em;
  text-align: left;

  a,
  span.current {
    height: 2em;
    padding: 0.2em 0.5em;
  }
}

.pagination {
  ul {
    justify-content: center;
  }

  ul li {
    padding: 0.2em 0 0.2em 0.8em;
  }

  .active a {
    color: #afbcc9;
  }

  .next a,
  .previous a {
    svg {
      width: 13px;
      height: 31px;
      fill: #0f509d;
    }

    &:hover svg {
      fill: #fff;
    }
  }

  .next a {
    svg {
      transform: rotate(180deg);
    }
  }

  .next {
    padding-left: 15px;
    border-left: 1px solid #ced6dd;
  }

  .previous {
    padding-right: 15px;
    border-right: 1px solid #ced6dd;
  }

  .last,
  li:nth-last-child(2) {
    padding-right: 15px;
  }
}
