// Le navigation
// target audiences

// here goes the fall back for non flexbox:

.target-audiences {
  div {
    display: table;
    padding: 0;
    margin: 0 -1px 0 -1px;

    a {
      position: relative;
      display: table-cell;
      width: 17%;
      padding: 12px 10px 10px 0;
      border: 1px solid @white;
      background: @green;
      color: @blue;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background: @blue;
        color: @green;

        h3 {
          color: @green;
        }
      }

      svg {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
        width: 3em;
        height: 3em;
      }

      h3,
      p {
        padding: 0;
        padding-left: 3.5em;
        margin: 0 0 10px;
      }

      h3 {
        font: 700 1.1em/1.2 @copy-font;
      }

      p {
        font: 400 0.8em/1.4 @copy-font;
      }
    }
  }
}

// all shiny and chrome flexbox:

.flexbox .target-audiences {
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      display: flex;
      width: 20%;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;

      @media (max-width: @screen-md-max) {
        display: flex;
        width: 33%;
        min-height: 76px;
        align-items: center;
      }

      @media (max-width: @screen-xs-max) {
        width: 50%;
        padding: 0 0.2em 0.3em;
        // text-align: center;

        svg {
          position: relative;
          top: auto;
          left: auto;
        }

        h3,
        p {
          padding: 0;
          padding-left: 0;
          margin: 10px 0;
        }

        h3 {
          font: 700 0.85em/1.2 @copy-font;
        }

        p {
          display: none;
        }
      }

      @media (min-width: @screen-lg-min) {
        width: auto;
        flex-basis: 19%;
        flex-grow: 1;
        flex-shrink: 0;
      }
    }

    a:last-child {
      @media (max-width: @screen-xs-max) {
        justify-content: center;
      }
    }
  }
}

.section-front-page .flexbox .target-audiences div a:last-child {
  @media (max-width: @screen-xs-max) {
    justify-content: center;
  }
}

// small slelectable version for the events search form

.target-audiences-small,
.flexbox .target-audiences-small {
  margin-top: 1em;
  font-size: 0.8em;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      display: flex;
      width: 20%;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;
      padding: 20px 10px 20px 0;

      @media (max-width: @screen-md-max) {
        display: flex;
        width: 33%;
        min-height: 76px;
        align-items: center;

        svg {
          top: 1em;
        }
      }

      @media (max-width: @screen-xs-max) {
        width: 50%;
        padding: 0 0.5em 0.3em;
        // text-align: center;

        svg {
          position: relative;
          top: auto;
          left: auto;
        }

        h3,
        p {
          padding: 0;
          padding-left: 0;
          margin: 10px 0;
        }

        h3 {
          font: 700 0.85em/1.2 @copy-font;
        }

        p {
          display: none;
        }
      }

      @media (min-width: @screen-lg-min) {
        width: auto;
        flex-basis: 19%;
        flex-grow: 1;
        flex-shrink: 0;
      }

      &::after {
        position: absolute;
        top: 0.4em;
        right: 0.4em;
        display: block;
        width: 0.9em;
        height: 0.9em;
        background: url(../img/icon-times.svg) no-repeat;
        background-size: contain;
        content: "";
      }

      h3 {
        text-align: center;
      }

      &:hover,
      &:hover h3 {
        background: lighten(@header-blue, 0%);
        color: fade(@blue, 100%);
      }

      &.inactive,
      &.inactive h3 {
        background: lighten(@header-blue, 0%);
        color: fade(@blue, 70%);

        &::after {
          display: none;
        }
      }
    }
  }

  &.pbh-variation {
    h3 {
      padding-left: 0;
    }

    a {
      width: 100%;
      justify-content: center;
      padding-right: 0;
    }
  }
}

#kitconcept-calendar .target-audiences,
.flexbox #kitconcept-calendar .target-audiences {
  div {
    a {
      &:hover,
      &:hover h3 {
        background: @green;
        color: @blue;
      }
    }
  }
}
