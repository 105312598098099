#global_statusmessage {
  padding-left: 15px;
}

.searchField {
  border: none;

  &:focus {
    outline: none;
  }
}

ul.vereinigungen-subcategory li {
  padding: 0.2em 0;
}

ul.vereinigungen-subcategory li::after {
  content: initial;
}

.searchPage {
  margin-top: 20px;

  input[type="text"] {
    width: 79%;
    padding: 0.4em 1em;
    border: 1px solid @line-color-gray;
    background: transparent;
    border-radius: 0;
    color: @font-color;
    font: 1em/2 @copy-font;
    line-height: 30px;
  }

  .actionMenuHeader {
    display: none;
  }

  input[type="submit"] {
    padding: 13px 20px;
  }

  .input-group {
    margin-bottom: 40px;
  }

  .actionMenu dt {
    width: initial;
  }

  .actionMenu dd {
    top: 30px;
    width: 500px;
  }

  .searchResults li {
    margin-bottom: 20px;
  }

  .autotoc-nav a {
    margin-right: 20px;

    &:first-child {
      margin-left: 20px;
    }
  }
}
// Removing special custom links
i.link-external,
i.link-https {
  display: none;
}
// i.link-external,
// i.link-https {
//   top: 5px;
// }

// i.link-external::after,
// i.link-https::after {
//   display: block;
//   width: 20px;
//   height: 20px;
//   margin-right: 4px;
//   background: url(/++theme++dpg-theme/img/icon-external-link.svg) 0 0 no-repeat;
//   content: "";
// }

// a:not([class])[href$=".pdf"],
// a:not([class])[href$=".docx"],
// a:not([class])[href$=".xlsx"],
// a:not([class])[href$=".pptx"] {
//   position: relative;
//   display: inline-block;
//   margin-left: 22px;

//   &::before {
//     position: absolute;
//     top: 7px;
//     left: -23px;
//     display: inline-block;
//     width: 20px;
//     height: 20px;
//     background: url(/++theme++dpg-theme/img/icon-download.svg) 0 0 no-repeat;
//     color: #ed4033;
//     content: "";
//   }
// }

.listing-item-body a {
  margin-left: 10px;
}

.small-lead-image {
  margin-right: 20px;
  float: left;
}

.newsImageContainer,
.contentImageContainer {
  overflow: hidden;
  margin: 0.3em 0 0.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);

  a,
  div {
    display: table;
    border: none;
  }

  a img,
  div img {
    display: block;
  }

  figcaption {
    display: none;
  }

  .image-inline {
    width: 100%;
  }
}

.portaltype-event .contentImageContainer {
  > div {
    width: 100%;
  }

  .image-inline {
    width: 60%;
  }
}

.portaltype-pressemitteilung .newsImageContainer,
.portaltype-news-item .newsImageContainer,
.portaltype-wichtigesausderdpg .newsImageContainer {
  figcaption {
    display: table-caption;
    caption-side: bottom;
  }
}

.newsImageContainer,
.headerImage-container {
  position: relative;

  a.crop {
    position: absolute;
    top: 0;
    right: 0;
    border: 0.3rem solid #fff;
    background: #fff;
    font-size: 0;
  }

  a.crop > i {
    font-size: initial;
  }
}

.contentImageContainer {
  width: initial;
  box-shadow: none;

  .image-left,
  .image-right {
    margin: 0;
  }

  &.image-left {
    margin: 0.5em 1em 0.5em 0;
  }

  &.image-right {
    margin: 0.5em;
  }
}

.plone-modal .modal-image img {
  width: 100%;
}

.termin {
  i.link-external::after {
    display: none;
  }
}

.documentByLine {
  display: none;
}

.entries .entry {
  margin-bottom: 10px;
}

.header-title {
  margin: 0;
}

.header-description {
  margin-top: 0;
}

.header-date {
  display: block;
  margin-top: 30px;
}

// Hide footer portlets
.plonetoolbar-portlet-manager:nth-child(2) {
  display: none;
}

.portletWrapper {
  margin-bottom: 20px;
}

.portletCollection {
  ul {
    padding: 0;
    list-style: none;

    a {
      text-decoration: none;
    }

    h3 {
      font-size: 1.2em;
      line-height: 1.2;
    }
  }

  a {
    border-bottom: 2px solid #e8e900;
    color: #00519d;
    font-weight: bold;
    text-decoration: none;
  }
}

#kitconcept-calendar {
  .autotabs {
    border: none;
    margin-top: 40px;
    box-shadow: none;

    .autotoc-nav {
      display: flex;
      justify-content: center;
      background: transparent;

      a {
        width: 47%;
        cursor: pointer;
        text-align: center;
      }
    }

    & + .pager-year h3 {
      margin: 20px;
    }
  }
}

.portaltype-document .autotoc-nav {
  background: #fff;
  border: none;
  > a {
    border-bottom: none;
  }
}

.headerImage {
  img {
    width: 100%;
  }

  p {
    text-align: right;
  }
}

.template-event_view {
  h3 {
    margin-top: 10px;
  }

  .event-actions {
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: @screen-xs-max) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    a {
      padding: 0.7em;
      border: 1px solid #00519d;
      margin-right: 20px;
      text-decoration: none;

      @media (max-width: @screen-xs-max) {
        margin-top: 10px;
      }
    }
  }

  .event-text {
    h4 {
      margin-top: 60px;
      font-size: 16px;
      font-weight: 600;
    }

    h2,
    p {
      width: 60%;

      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }
  }
}

.template-zweispaltig_mit_bild .row,
.template-zweispaltig_ohne_bild .row {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 35em) {
  .portaltype-event dl {
    display: flex;
    border-bottom: none;

    dt {
      width: auto;
      flex: 1;
    }

    dd {
      width: auto;
      flex: 1 1 60%;
    }
  }
}

.portlet.portletEvents .termine a {
  text-decoration: none;
}

// slider inside content area
.content {
  .gallery-block {
    margin-bottom: 30px;

    p {
      margin: 0;
      overflow-wrap: break-word;
    }

    figcaption {
      padding: 10px;
    }

    h4 {
      margin: 0;
      color: #777;
      font-weight: bold;
    }

    .copyright {
      font-style: italic;
    }
  }

  ul.slides,
  ul.flex-direction-nav {
    margin: 0;

    & li::after {
      content: none;
    }
  }

  ul.slides {
    background-color: #efefef;
  }

  .flex-direction-nav {
    position: absolute;
    top: 55%;
    right: 90px;
    left: 90px;
  }

  ol.flex-control-nav {
    top: 300px;

    @media (max-width: @screen-sm-max) {
      top: 100px;
    }
  }
}

.portlet.author a,
.portlet.portletStaticText a {
  border-bottom: 2px solid #e8e900;
  color: #00519d;
  font-weight: bold;
  text-decoration: none;
}

.events-block {
  .termine {
    display: flex;
    flex-wrap: wrap;

    .termine-item {
      margin-right: 27px;
    }

    a {
      flex: 0 1 50%;
      border: 0;

      p {
        color: #666;
        font-weight: 500;
      }
    }
  }
}

.portletEvents {
  .termine {
    p {
      color: #666;
      font-weight: 500;
    }
  }
}

// New mobile menu custom
#mobile-nav {
  .mobile-logo {
    position: relative;
    top: -19px;
    display: block;
    padding: 0;
    margin: 0 auto;

    svg {
      width: 68px;
      height: 34px;
    }
  }

  @media (min-width: @screen-sm-max) {
    display: none;
  }

  &:not(.mm-menu) {
    display: none;
  }

  .icon {
    color: #99b9d7;
  }

  .mm-panel,
  .mm-navbar {
    background: #00519d;
    color: #99b9d7;
    font: 400 1.1em/1.6 "Roboto", sans-serif;
  }

  .mm-listitem {
    border-color: #2a5ba7;
    border-width: 1px;
    color: #99b9d7;
    font: 400 1.1em/1.6 "Roboto", sans-serif;
  }

  .mm-btn::after,
  .mm-btn::before {
    border-color: #99b9d7;
  }

  .mobile-support {
    ul:first-child {
      padding-bottom: 10px;
      border-bottom: 1px solid #2a5ba7;
      margin: 0 0 0 20px;
    }

    ul:last-child {
      margin-top: 10px;
    }
  }
}

#parent-fieldname-abbinder {
  border-top: 1px solid #a2a2a2;
  margin-top: 40px;
}

// timeline
#content .timeline {
  &::before {
    width: 4px;
  }

  > li {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  > li::after {
    display: none;
  }

  > li.clearfix {
    clear: both;
  }

  > li > .timeline-badge > i::before {
    position: absolute;
    z-index: 400;
    top: -11px;
    left: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid #eee;
    background: #fff;
    border-radius: 50%;
    content: " ";
  }

  > li > .timeline-panel > .timeline-date {
    display: flex;
    width: 100%;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    padding: 4px;
    background-color: #eceff2;
    color: #00519d;
    font-weight: bold;
    text-align: right;
    text-decoration: none;
  }

  > li.timeline-inverted > .timeline-panel > .timeline-date {
    text-align: left;
  }

  .timeline-body > div {
    margin-bottom: 20px;

    > p {
      padding: 0 20px;
      margin-bottom: 0;
    }

    > p + p {
      margin-top: 5px;
    }
  }

  > li:nth-child(1) {
    margin-top: 0;
  }

  > li:nth-child(even) {
    margin-top: 60px;
  }
}
