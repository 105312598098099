// Le breadcrumb

nav.breadcrumb {
  padding:2.5em 0 0;
  color:@gray-light;
  font:400 .7em/1.6 @copy-font;
  margin-bottom:2.5em;

  ul {
    li {
      display:inline-block;
      position: relative;

      &:after {
        content:"/";
        position: absolute;
        top:0;
        right:0;
      }
      &:last-child:after {
        display:none;
      }
      a, span {
        display:block;
        color:@gray-light;
        padding: 0 0.9em 0 0.2em;
        border:none;
      }
      a {
        //text-decoration: underline;
      }
      span {
        font-weight:700;
      }
      svg {
        position: relative;
        top:-.1em;
        margin-right:.5em;
      }
    }
  }
}