// teaser-boxes

.meldung-item {
  margin-bottom:1em;

  .meldung-item-body {
    padding-bottom:1em;
    border-bottom:1px solid @gray-lighter;
  }
  h3 {
    margin:0;
    padding:0;
    line-height:1.2;
    font-size:1.2em;
  }
  p {
    margin:0;
    padding:0;
    font:.85em/1.6 @copy-font;
  }
}
