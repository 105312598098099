footer {
  background: @blue;
  margin-top: 1rem;

  .footer-upper {
    border-bottom: 1px solid fade(@white, 30%);

    .logo {
      padding: 1.6em 0;

      svg {
        width: 90px;
        height: 45px;
        color: @white;
      }
    }

    nav {
      padding: 1.9em 0 1em;
      text-align: right;

      @media (max-width: @screen-xs-max) {
        text-align: left;
      }

      ul {
        > li {
          display: inline-block;

          a {
            display: block;
            padding: 0.3em 0 0.2em 1em;
            color: fade(@white, 100%);
            font-size: 0.9em;
            font-weight: 400;
          }

          &.bordered {
            border: 1px solid @green;

            a {
              padding-right: 1em;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .footer-middle {
    padding: 1em 0 2em;
    border-bottom: 1px solid fade(@white, 30%);

    nav {
      ul {
        vertical-align: top;

        > li {
          display: inline-block;
          width: 16.3333333%;
          vertical-align: top;

          @media (max-width: @screen-md-max) {
            width: 24.66666%;
          }

          @media (max-width: @screen-sm-max) {
            width: 32.666666%;
          }

          @media (max-width: @screen-xs-max) {
            width: 100%;
          }

          a {
            display: block;
            padding: 0.5em 0;
            color: @white;
            font-size: 0.9em;
            font-weight: 500;
          }

          ul {
            display: block;

            li {
              display: block;
              width: 100%;

              a {
                padding: 0.1em 0;
                color: fade(@white, 50%);
                font-size: 0.85em;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .footer-lower {
    p {
      padding: 0.5em 0;
      color: fade(@white, 60%);
      font-size: 0.9em;
      font-weight: 400;
    }

    nav {
      padding: 0.9em 0;
      text-align: right;

      @media (max-width: @screen-xs-max) {
        text-align: left;
      }

      ul {
        > li {
          display: inline-block;

          a {
            display: block;
            padding: 0.5em 0 0.5em 1em;
            color: fade(@white, 60%);
            font-size: 0.9em;
            font-weight: 400;

            @media (max-width: @screen-xs-max) {
              padding: 0.5em 1em 0.5em 0;
            }
          }
        }
      }
    }
  }
}

main footer {
  background-color: transparent;
}
