// some general rules

// make the footer always appear at the bottom of the page

.the-wrapper {
  // The tables inside are not overflowing because of the footer pusher
  // removing for now...
  // https://trello.com/c/wnFsD18N/57-youtube-video-has-wrong-ratio
  // display: flex;
  // flex-direction: column;
  min-height: 100vh;

  .content {
    flex-grow: 1;
    margin-top: -2em;

    .header-image-container {
      margin-top: 2em;
    }

  }
}
