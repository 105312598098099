// teaser-boxes

.teaser {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.teaser-item {
  margin-bottom: 1em;

  .teaser-item-image {
    margin-bottom: 0.5em;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .teaser-item-body {
    padding-bottom: 1em;

    h3 {
      padding: 0.2em 0;
      margin: 0;
      font-size: 1.2em;
      line-height: 1.2;
    }

    p {
      padding: 0;
      margin: 0;
      font: 0.85em/1.6 @copy-font;
    }
  }
}
