// the four boxes

// here goes the fall back for non flexbox:

.member-benefits {
  div {
    display:table;
    margin:0 -1px 0 -1px;
    padding:0;
    a {
      display:table-cell;
      width:50%;
      position: relative;
      border:1px solid @white;
      background:@green;
      text-decoration: none;
      color:@blue;
      padding:25px 20px 20px 0;


      // derzeit ausser Kraft gesetzt
      &:hover {
        background:@green;
        color:@blue;
        cursor: inherit;

        h3 {
          color:@blue;
        }
      }
      svg {
        position: absolute;
        top:.7em;
        left:.7em;
        width:3.5em;
        height:3.5em;

      }
      h3, p {
        padding:0;
        margin:0 0 10px 0;
        padding-left:80px;
      }
      h3 {
        font:700 1.1em/1.2 @copy-font;
      }
      p {
        font:400 .8em/1.4 @copy-font;
      }
    }
  }
}

// all shiny and chrome flexbox:

.flexbox .member-benefits {
  div {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;

    a {
      display: block;
      width: 50%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-wrap:wrap;

      @media(max-width: @screen-md-max) {
        width: 50%;
      }
      @media(max-width: @screen-xs-max) {
        width: 100%;

        svg {        }
        h3, p {        }
        h3 {
          font:700 .85em/1.2 @copy-font;
        }
        p {        }
      }
    }
  }
}
