.content ul li.DayPicker_weekHeader_li {
  padding: initial;

  &::after {
    content: none;
  }
}

.content ul.DayPicker_weekHeader_ul {
  margin: initial;
}

table.CalendarMonth_table {
  margin: initial;
}

// table tr:nth-child(even) td {
//   background: inherit;
// }

.DateRangePicker {
  table th:not(.CalendarDay__default),
  table td:not(.CalendarDay__default) {
    border: none;
  }
}

.content {
  .DateRangePickerInput__withBorder {
    border: 1px solid #dfdfdf;
    border-radius: 0;
  }

  .DateInput_input__focused {
    padding: 6.4px 16px 4.4px !important;
    border-bottom: 2px solid #00519d !important;
  }

  .CalendarDay__default:hover {
    border: 1px solid #eceff2;
    background: #eceff2; //background
    color: #00519d; //text
  }
  // Will edit everything selected including everything between a range of dates
  .CalendarDay__selected_span {
    // border: 1px solid red; //default styles include a border
    border: 1px solid #eceff2;
    background: #eceff2; //background
    color: #00519d; //text
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    border: 1px solid #00519d;
    background: #00519d;
    color: white;
  }

  // Will edit selected date or the endpoints of a range of dates
  .CalendarDay__selected {
    border: 1px solid #00519d;
    background: #00519d;
    color: white;
  }

  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    border: 1px solid #00519d;
    background: #00519d;
    color: white;
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    border: 1px solid #eceff2;
    background: #eceff2;
    color: #00519d; //text
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0.4em 0;
  }

  .DateRangePickerInput_calendarIcon_svg {
    width: 20px;
    height: 21px;
    fill: #00519d;
  }

  .DateRangePickerInput_clearDates {
    width: 38px;
    margin: 0 5px;

    svg {
      width: 12px !important;
      height: 12px !important;
      fill: rgb(130, 136, 138) !important;
      vertical-align: middle !important;
    }
  }

  .DateRangePickerInput__showClearDates {
    @media (max-width: @screen-md-max) {
      padding-right: 15px;
    }

    @media (max-width: @screen-sm-max) {
      padding-right: 20px;
    }
  }
}
