// wrap search and main nav in a flexbox wrapper
// ===============================================================

// main navigation with pull-down
// ================================================================

// some general things on navs:

nav {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      a {
        text-decoration: none;
      }
    }
  }
}

.main-navi {
  // default not visible (mobile)
  display: none;
  border-bottom: 1px solid @line-color-gray;
  background: @header-blue;

  nav#nav ul > li > a.toggle-me {
    display: none;
  }
}

@media (min-width: @screen-md-min) {
  .main-navi {
    display: block !important;
  }

  nav#nav {
    ul {
      position: relative;
      display: table;
      width: 100%;
      padding: 0;
      margin: 0;

      ul {
        display: block;
      }
    }

    ul li {
      position: relative;
      display: table-cell;
      width: 1%;
      padding: 0;
      margin: 0;

      li {
        display: block;
        float: left;
      }
    }
    // nur die erste ebene:
    & > ul > li > a::after {
      position: absolute;
      //top:1.0em;
      right: 0;
      display: inline-block;
      width: 2em;
      height: 1.7em;
      background: url(../img/icon-chevron-down.svg) no-repeat center center;
      content: "";
    }
    //& > ul > li:last-child:after {
    //  display:none;
    //}
    ul li a {
      display: block;
      padding: 1.2em 1.6em 0.7em 0.4em;
      margin: 0;
      color: @blue;
      font: 600 1em/1.6 @copy-font;
      text-align: center;
      transition: color 0.2s linear, border-color 0.2s linear,
        background-color 0.2s linear;
      white-space: nowrap;
    }

    ul li.inactive > a {
      border-color: @blue;
      color: @blue;
      font-weight: 900;
    }

    ul li li.inactive > a {
    }

    ul > li > a {
      display: block;
      border-bottom: 6px solid transparent;
      text-align: center;
    }

    ul li a:hover {
      background: @header-blue;
      color: @blue;
    }
    // nur erste Ebene:
    > ul > li > a:hover,
    > ul > li.is-hover > a {
      background: fade(@white, 60%);
      border-color: fade(@blue, 60%);
      color: darken(@blue, 5%);
    }

    ul li:hover > a {
    }

    /* Drop-Down Navigation */
    ul li:hover > ul {
      opacity: 1;
      // delaying the display of the submenu
      transition-delay: 0.1s;
      visibility: visible;
    }

    ul ul,
    ul ul li ul {
      position: absolute;
      z-index: 99999;
      width: 220px;
      padding: 0;
      margin: 0;
      background: fade(@white, 95%);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      list-style: none;
      opacity: 0;
      transition: opacity 0.2s linear, visibility 0.2s linear;
      // we're delaying the transition BACK a little more... this does the magic of NOT loosing focus that easy.
      transition-delay: 0.2s;
      visibility: hidden;
    }

    ul ul {
      top: 61px;
      left: 0;
    }

    ul ul li ul {
      top: 0;
      left: 220px;
    }

    ul ul li {
      width: 100%;
      border: 0 none;
      clear: both;

      &.hasChilds::after {
        position: absolute;
        right: 8px;
        bottom: 5px;
        color: lighten(@gray, 10%);
        content: "\f105";
        font-family: fontawesome;
        font-size: 16px;
      }
    }

    ul ul li a {
      display: block;
      padding: 7px 17px;
      border-bottom: 1px solid @line-color-gray;
      margin: 0;
      background: none;
      clear: both;
      font-size: 0.9em;
      text-align: left;
      text-decoration: none;
      text-transform: none;
      white-space: normal;

      &:hover {
        //background: #fff;
        //text-decoration: underline;
        color: @blue;
      }
    }

    ul ul li:last-child a {
      border: 0;
    }
  }
}

// medium desktop

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .main-navi .container {
    // width:100%;
  }

  nav#nav {
    ul li a {
      font: 700 0.91em/1.8 @headline-font;
    }
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .main-navi .container {
    width: 100%;
  }

  nav#nav {
    ul li a {
      padding: 1.6em 0.3em 1.5em;
      font: 700 0.8em/1.8 @headline-font;
    }
  }

  nav#nav ul ul {
    top: 84px;
    left: 0;
  }

  nav#nav ul ul li a {
    padding: 5px 0;
    margin: 0 20px;
    font-size: 0.8em;
  }
}
