// the listings

// general

// single col

.listing {
  padding-top: 2em;

  .listing-item {
    display: flex;
    min-height: 160px;
    flex-direction: row;
    margin-bottom: 1em;

    @media (max-width: @screen-xs-max) {
      flex-direction: column;
    }
  }

  .listing-item-image {
    width: 90px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 1em;
    margin-right: 30px;

    img {
      width: 100%;
    }

    @media (max-width: @screen-xs-max) {
      width: 70px;
      margin-right: 25px;
    }
  }

  .listing-item-image.teaser {
    width: auto;
    min-width: 264px;
  }

  .listing-item-body {
    width: auto;
    flex: 1 auto;
    border-bottom: 1px solid @line-color-gray;

    h3 {
      padding: 0;
      margin: 0;
      font-size: 1.2em;
    }

    p {
      font-size: 0.95em;

      @media (max-width: @screen-xs-max) {
        font-size: 0.9em;
        line-height: 1.4;
      }
    }
  }
}

// double col

.listing.listing-double-col {
  .listing-item-image {
    width: 70px;
    margin-right: 25px;
  }

  .listing-item-body {
    p {
      font-size: 0.9em;
      line-height: 1.4;
    }
  }
}
