// Le Slide-show...

.hero {
  // for desigening the subheader:

  //display:none;

  position: relative;
  top: -1px;
  margin-bottom: 5em;

  &::after {
    position: absolute;
    top: 0;
    bottom: 40px;
    width: 100%;
    border-bottom: 1px solid @line-color-gray;
    background: @header-blue;
    content: "";
  }

  .flexslider {
    position: relative;
    z-index: 1;
  }
}

// header image in subseite

section.header-image {
  margin-top: 2em;

  img {
    width: 100%;
  }
}
