// main navigation MOBILE with subnavi on click
// ================================================================

@media (max-width: @screen-md-min) {
  .naviContainer {
    position: relative;
  }

  nav#nav-off {
    ul {
      position: relative;
      display: block;
      width: 100%;
      padding: 0 0 1em;
      margin: 0;

      ul {
        display: block;
      }
    }

    ul li {
      position: relative;
      display: block;
      padding: 0;
      margin: 0;
    }

    > ul > li:last-child a {
      border-bottom: 1px solid @line-color-blue;
    }

    ul li a {
      display: block;
      padding: 0.4em 0;
      border-top: 1px solid @line-color-blue;
      margin: 0 0 0 1em;
      color: @navi-mobile-text;
      font: 400 1.1em/1.6 @copy-font;
      hyphens: auto;
      // white-space: nowrap;
      overflow-wrap: break-word;
      transition: color 0.2s linear, border-color 0.2s linear;
      word-wrap: break-word;
    }

    ul li a:not(.toggle-me) {
      width: 61vw;
    }

    ul li.inactive > a {
      color: @green;
      font-weight: 700;
    }

    ul li li.inactive > a {
      //background: lighten(@green, 0%);
    }

    ul > li > a {
      display: block;
    }

    ul ul,
    ul ul li ul {
      position: relative;
      z-index: 99999;
      display: none;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    ul ul li {
      width: 100%;
      border: 0 none;
      clear: both;
    }

    ul ul li a {
      display: block;
      padding: 0.6em 0 0.6em 3.3em;
      border: 0 none;
      background: none;
      clear: both;
      font-size: 0.9em;
      text-align: left;
      text-decoration: none;
      text-transform: none;
      white-space: normal;

      &:hover {
        //background: #fff;
        text-decoration: underline;
      }
    }

    ul ul li a:not(.toggle-me) {
      width: initial;
      padding-right: 13vw;
      padding-left: 4vw;
    }

    ul ul ul li a {
      display: block;
      padding: 0.6em 0 0.6em 5.3em;
      border: 0 none;
      background: none;
      clear: both;
      font-size: 0.9em;
      text-align: left;
      text-decoration: none;
      text-transform: none;
      white-space: normal;

      &:hover {
        //background: #fff;
        text-decoration: underline;
      }
    }

    ul ul ul li a:not(.toggle-me) {
      width: initial;
      padding-right: 13vw;
      padding-left: 8vw;
    }

    // toggle indicator after first hierarchy
    a.toggle-me {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding-left: 0;
      border: 0;
      border-bottom: 0 !important;
      border-left: 1px solid @line-color-blue;
      content: "";
    }

    a.toggle-me > span {
      display: inline-block;
      width: 40px;
      height: 10px;
      margin: 0;
      background: url(../img/icon-chevron-down-light.svg) no-repeat center
        center;
      background-size: 40%;
      content: "";
      opacity: 0.7;
      transform: rotate(-90deg);
    }

    li:not(.has_subtree) a.toggle-me {
      display: none;
    }

    .open > a.toggle-me > span {
      transform: rotate(0deg);
    }

    // this an experimental coloring of sub items
    // truly cascading styles ;o)

    ul {
      background: transparent;

      ul {
        background: darken(@blue, 3%);

        ul {
          background: darken(@blue, 6%);

          ul {
            background: darken(@blue, 9%);
          }
        }
      }
    }
  }
}

// the mobile search
// ====================================

.mobile-search {
  padding: 12px 18px 11px;
}

.search-inner {
  padding: 3px 0;
  background: darken(@blue, 5%);

  input {
    width: 100%;
    padding-left: 2.2em;
    border: 0 solid black;
    background: transparent;
    color: @white;
    outline: none !important;
  }
}

.icon-container {
  position: absolute;
  top: 14px;
  left: 26px;
  width: 1.2em;
  height: 1.6em;
}

svg.icon-search-mobile {
  width: 1em;
  height: 1em;
  color: @navi-mobile-text;
}

// mobile support
// ======================================

.mobile-support {
  ul {
    display: block;
    margin: 0 0 10px 20px;

    li {
      display: inline-block;

      a {
        padding: 0.5em 1em 0.5em 0;
        color: @navi-mobile-text;
        font-size: 90%;
      }
    }
  }
}

// mobile language and login
// ==========================================

.language-login-mobile {
  // This equals vertical space due to the new items "mein DPG"
  // padding-bottom: 16px;
  border-bottom: 1px solid @line-color-blue;
  margin: 0 0 10px 20px;
  color: @navi-mobile-text;
  font-size: 90%;

  a {
    color: @navi-mobile-text;
    text-decoration: none;
  }

  .language,
  .login {
    display: inline-block;
    padding: 1em 1em 1em 0;
  }

  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
  }

  .language {
    position: relative;

    .icon.icon-globe {
      margin: 0;
    }

    .icon-chevron-down {
      width: 10px;
      height: 6px;
    }

    ul {
      position: absolute;
      z-index: 9999;
      top: 4em;
      left: 0;
      display: block;
      width: 11em;
      padding: 0;
      margin: 0;
      background: #fff;
      background: fade(@white, 95%);
      box-shadow: 0 2px 2px fade(@black, 20%);
      list-style: none;
      opacity: 0;
      transition: opacity 0.2s linear, visibility 0.2s linear;
      transition-delay: 0.2s;
      visibility: hidden;

      li {
        a {
          display: block;
          padding: 7px 17px;
          border-top: 1px solid #dfdfdf;
          margin: 0;
          background: none;
          clear: both;
          color: @blue;
          font-size: 1em;
          font-weight: 600;
          text-align: left;
          text-decoration: none;
          text-transform: none;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .login.logged-in {
    .login-toggle {
      display: none;
    }

    .icon.icon-lock {
      margin: 0;
    }

    ul {
      display: flex;
      margin-top: 20px;

      li {
        margin-right: 10px;
      }
    }
  }

  .intranet-link {
    margin-right: 0;
    margin-left: 32px;
  }
}

// color the icons mobile....

.product-navigation-offcanvas {
  .icon {
    color: @navi-mobile-text;
  }
}
